import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import TitleStyled from "../components/styles/titleStyles"
import JobSinlgeStyled from "../components/styles/JobSinlgeStyles"
import SEO from "../components/seo"
import Link from "gatsby-link"
import JobForm from "../components/jobForm"
import back from "../../static/back.png"

const careerTemplate = ({ data, pageContext }) => {
  const tmp = "/career/" + pageContext.slug
  const pageContextTmp = { ...pageContext, slug: tmp }

  const {
    backText,
    Heading,
    description,
    HeadingSidebar,
    cityFeed,
    descriptionSidebar,
    namePlaceholder,
    infoFooterSidebar,
    emailPlaceholder,
    questionPlaceholder,
    checkbox,
    buttonFile,
    buttonSidebar,
    checkboxError,
    nameError,
    emailError,
    fileError,
    fileErrorSize,
    thx,
    metaTitle,
    metaDescription,
    metaImage,
  } = data.swapi.carrerCollection[0]

  return (
    <Layout pageContext={pageContextTmp}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        img={metaImage ? metaImage.path : ""}
        lang="pl"
      />
      <JobSinlgeStyled>
        <div className="container">
          <div className="job__back">
            <Link to="/career/#careerFeed">
              <img src={back} alt="back" />
              {backText}
            </Link>
          </div>
          <div className="job">
            <div className="job__content">
              <TitleStyled textAlign="left" linePosition="left" color="#000000">
                <h1 dangerouslySetInnerHTML={{ __html: Heading }}></h1>
              </TitleStyled>
              <div
                className="job__description"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            </div>
            <div className="job__sidebar">
              <div>
                <TitleStyled
                  textAlign="left"
                  linePosition="left"
                  color="#000000"
                >
                  <h2 dangerouslySetInnerHTML={{ __html: HeadingSidebar }}></h2>
                </TitleStyled>
                <p className="job__palce">{cityFeed}</p>
                <p className="job__sidebar__description">
                  {descriptionSidebar}
                </p>
                <JobForm
                  job={HeadingSidebar}
                  namePlaceholder={namePlaceholder}
                  infoFooterSidebar={infoFooterSidebar}
                  emailPlaceholder={emailPlaceholder}
                  questionPlaceholder={questionPlaceholder}
                  checkbox={checkbox}
                  buttonFile={buttonFile}
                  buttonSidebar={buttonSidebar}
                  nameError={nameError}
                  emailError={emailError}
                  fileError={fileError}
                  fileErrorSize={fileErrorSize}
                  checkboxError={checkboxError}
                  thx={thx}
                />
              </div>
            </div>
          </div>
        </div>
      </JobSinlgeStyled>
    </Layout>
  )
}

export default careerTemplate

export const query = graphql`
  query CarrerTemplateQuery($id: String!) {
    swapi {
      carrerCollection(_id: $id) {
        backText
        Heading
        description
        HeadingSidebar
        descriptionSidebar
        namePlaceholder
        infoFooterSidebar
        emailPlaceholder
        questionPlaceholder
        checkbox
        buttonFile
        buttonSidebar
        metaTitle
        metaDescription
        cityFeed
        checkboxError
        nameError
        emailError
        fileError
        fileErrorSize
        thx
        metaImage {
          path
        }
      }
    }
  }
`
