import styled from "styled-components"
import checkbox from "../../../static/job_checkbox.svg"

const JobSinlgeStyled = styled.div`
  padding-top: 70px;
  .job__back {
    padding-bottom: 32px;
    a {
      font-weight: 300;
      display: flex;
      align-items: center;
      font-size: 24px;
      img {
        margin-right: 12px;
      }
    }
  }
  .job {
    display: flex;
    justify-content: space-between;
    padding-bottom: 70px;
    @media (max-width: 1080px) {
      flex-wrap: wrap;
    }
  }
  .job__content {
    max-width: calc(66.66% - 8px);
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);
    padding: 60px 45px 70px;
    background: #fff;
    @media (max-width: 1080px) {
      padding: 40px 25px 40px;
    }
    @media (max-width: 1080px) {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }
  .job__description {
    padding-top: 40px;
    &:after {
      content: "";
      display: block;
      margin: 50px auto 0;
      width: 65px;
      height: 1px;
      background: #000000;
    }
    ul, ol {
      list-style-type: initial;
      padding-left: 1rem;
    }
    a {
      word-wrap: break-word;
      text-decoration: underline;
    }
    p {
      margin: 0;
      padding-bottom: 20px;
      font-size: 18px;
    }
    h2 {
      margin: 0;
      padding: 20px 0;
      font-weight: 600;
      font-size: 20px;
    }
  }
  .job__sidebar {
    max-width: calc(33.33% - 8px);
    @media (max-width: 1080px) {
      max-width: 100%;
    }
    & > div {
      box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);
      padding: 40px 40px 50px;
      background: #fff;
      @media (max-width: 1080px) {
        padding: 40px 20px 40px;
      }
    }
    h2 {
      font-size: 24px;
    }
  }
  .job__palce {
    font-size: 20px;
    font-weight: 300;
    margin: 0;
    padding: 20px 0 30px;
  }
  .job__sidebar__description {
    font-size: 18px;
    font-weight: 300;
    padding-bottom: 45px;
    margin: 0;
  }
  .job__form__checkbox {
    position: relative;
    p {
      font-size: 8px;
      line-height: 11px;
      padding-left: 18px;
    }
    input[type="checkbox"] {
      visibility: hidden;
      position: absolute;
    }
    input[type="checkbox"] ~ span:first-of-type:before {
      content: "";
      width: 10px;
      height: 10px;
      border: 1px solid #000;
      display: block;
      position: absolute;
      top: 0px;
      left: 0;
      line-height: 1.3;
      box-sizing: border-box;
      line-height: 0.5;
    }
    input[type="checkbox"]:checked ~ span:first-of-type:before {
      background: url('${checkbox}'), #fff;
      background-position:center;
      background-repeat:no-repeat;
    }
  }
  .job__form__info {
    p {
      font-size: 8px;
      line-height: 11px;
      padding-left: 18px;
    }
  }
  .job__form {
    textarea,
    input[type="email"],
    input[type="text"] {
      width: 100%;
      display: block;
      font-size: 15px;
      padding: 7px 10px;
      margin-bottom: 0px;
      border: 1px solid #000;
      &::placeholder {
        color: #b5b5b5;
      }
    }
    textarea {
      height: 60px;
      font-family: "Helvetica Neue";
    }
    input[type="file"] {
      position: absolute;
      visibility: hidden;
    }
    div[class*="ButtonStyles"] {
      button,
      span {
        font-weight: 300;
      }
    }
    .job__form__row {
      padding-bottom: 20px;
      div[class*="ButtonStyles"] {
        justify-content: flex-start;
      }
    }
    .job__form__question {
      padding-bottom: 20px;
    }
    .error {
      color: red;
      font-size: 12px;
      display: block;
      padding-bottom: 10px;
    }
    & > div[class*="ButtonStyles"] {
      padding-top: 50px;
      justify-content: flex-end;
    }
  }
`

export default JobSinlgeStyled
