import React, { useState } from "react"
import { useForm } from "react-hook-form"
import ButtonStyled from "./styles/ButtonStyles"
import myInitObject from "../ultis/variable"

const JobForm = ({
  job,
  namePlaceholder,
  infoFooterSidebar,
  emailPlaceholder,
  questionPlaceholder,
  checkbox,
  buttonFile,
  buttonSidebar,
  nameError,
  emailError,
  fileError,
  fileErrorSize,
  checkboxError,
  thx,
}) => {
  const { handleSubmit, register, errors } = useForm()
  const [validateFile, setValidateFile] = useState(false)
  const [fileName, setFileName] = useState("")
  const [sendingPass, setsendingPass] = useState(false)

  const onSubmit = (values, e) => {
    if (!validateFile) {
      const message = document.querySelector("textarea").value
      const formData = new FormData()
      formData.append("file", values.file[0])
      formData.append("name", values.name)
      formData.append("email", values.email)
      formData.append("message", message)
      formData.append("job", job)
      formData.append("checkboxOne", values.RODO)
      return fetch(myInitObject.cockpitUrl + "jobApplication/", {
        method: "post",
        body: formData,
      }).then(response => {
        if (response.status === 200) {
          setsendingPass(true)
          e.target.reset()
        }
      })
    }
  }

  function fileInput(_this) {
    const inputFile = document.querySelector("input[type=file]")
    const sizeFile = inputFile.files[0].size
    setFileName(inputFile.value.split(/(\\|\/)/g).pop())
    if (
      sizeFile > 2000000 ||
      !hasExtension(inputFile, [".pdf", ".doc", ".docx", ".rtf", ".odt"])
    ) {
      setValidateFile(true)
    } else {
      setValidateFile(false)
    }
  }

  function hasExtension(inputFile, exts) {
    var fileName = inputFile.value
    return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
      fileName
    )
  }

  return (
    <form className="job__form" onSubmit={handleSubmit(onSubmit)}>
      <div className="job__form__name">
        <label>
          <input
            type="text"
            name="name"
            placeholder={namePlaceholder}
            ref={register({
              required: true,
            })}
          />
        </label>
        <span className="error">{errors.name && nameError}</span>
      </div>
      <div className="job__form__email">
        <label>
          <input
            type="email"
            name="email"
            placeholder={emailPlaceholder}
            ref={register({
              required: true,
            })}
          />
        </label>
        <span className="error">{errors.email && emailError}</span>
      </div>
      <div className="job__form__row">
        <label>
          <input
            onChange={value => fileInput(value)}
            type="file"
            name="file"
            accept=".doc,.pdf,.docx,.rtf,.odt"
            ref={register({
              required: true,
            })}
          />

          <ButtonStyled dark={true}>
            <span>{buttonFile}</span>
          </ButtonStyled>
        </label>
        {fileName ? <span>{fileName}</span> : ""}
        {validateFile ? <span className="error">{fileErrorSize}</span> : ""}
        <span className="error">{errors.file && fileError}</span>
      </div>
      <div className="job__form__question">
        <label>
          <textarea name="question" placeholder={questionPlaceholder} />
        </label>
      </div>
      <div className="job__form__checkbox">
        <label>
          <input
            type="checkbox"
            name="RODO"
            value="true"
            ref={register({
              validate: value => value === "true",
            })}
          />
          <span></span>
          <p>{checkbox}</p>
        </label>
        <span className="error">{errors.RODO && checkboxError}</span>
      </div>
      <div
        className="job__form__info"
        dangerouslySetInnerHTML={{ __html: infoFooterSidebar }}
      ></div>
      <ButtonStyled dark={true}>
        <button type="submit">{buttonSidebar}</button>
      </ButtonStyled>
      {sendingPass ? <p>{thx}</p> : ""}
    </form>
  )
}

export default JobForm
